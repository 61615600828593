.react-select-input {
  /* Not really how we should do this - you should pass a custom style function (see ReactSelectInput in envoc-core) to react select, but that's not exposed via our forms API so this hack will suffice */
  @apply rounded-md shadow-sm mt-1;
  > div {
    &:first-of-type {
      /* This is the 'control' that wraps the 'value container' */

      @apply rounded-md border-gray-300 ring-pbpurple-500 ring-offset-0 focus-within:ring-1 focus-within:border-pbpurple-500;
      /* Required exact size because of issues with the input height not matching other form controls and padding already being handled by react-select in a way that was hard to adjust */
      height: 42px;
    }

    svg {
      @apply cursor-pointer;
    }
  }
  &.is-invalid {
    > div {
      &:first-of-type {
        @apply rounded-md border-red-300 ring-red-500 ring-offset-0 focus-within:ring-1 focus-within:border-red-500;
      }

      > div:nth-of-type(1) {
        > div {
          @apply text-red-300;
        }
      }
    }
  }

  &.disabled {
    @apply pointer-events-auto;
    > div {
      &:first-of-type {
        @apply bg-gray-50 bg-no-repeat bg-right-2 bg-5 cursor-not-allowed;

        /* note: https://heroicons.com/#lock-closed and grey-400 is used here converted to hsl for fill - see: https://yoksel.github.io/url-encoder/ */
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='hsl(218, 11%, 65%)'%3E%3Cpath fill-rule='evenodd' d='M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z' clip-rule='evenodd' /%3E%3C/svg%3E");

        > div:nth-of-type(2) {
          @apply hidden;
        }
      }
    }
  }

  /* Note: the '+' operator is the 'Adjacent sibling combinator' */
  > span + div + div {
    &:not(:last-child) {
      /* This is the pop-over for react select */
      @apply rounded-md p-2;
      > div {
        @apply flex flex-col gap-1;
        > div {
          @apply rounded-md;
        }
      }
    }
  }
  input {
    @apply !ring-0;
  }
}
