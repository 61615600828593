.react-date-picker {
  @apply font-sans;
  .react-date-picker__wrapper {
    @apply h-10 border-none;
  }
  .react-date-picker__inputGroup {
    @apply pl-3 cursor-pointer;
  }
  svg {
    @apply !stroke-current text-gray-300 hover:text-gray-500;
  }

  .react-date-picker__button {
    @apply cursor-pointer;
  }

  &.react-date-picker--disabled {
    @apply bg-gray-50 bg-no-repeat bg-right-2 bg-5 cursor-not-allowed;

    /* note: https://heroicons.com/#lock-closed and grey-400 is used here converted to hsl for fill - see: https://yoksel.github.io/url-encoder/ */
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='hsl(218, 11%, 65%)'%3E%3Cpath fill-rule='evenodd' d='M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z' clip-rule='evenodd' /%3E%3C/svg%3E");

    .react-date-picker__inputGroup {
      @apply cursor-not-allowed;
    }
    .react-date-picker__button {
      @apply hidden;
    }
    input {
      @apply cursor-not-allowed text-gray-600;
    }
  }

  input {
    @apply !ring-0 !bg-transparent text-black;
  }

  .react-calendar__navigation {
    @apply gap-1;
  }
  .react-calendar__navigation__arrow,
  .react-calendar__navigation__label {
    @apply rounded-md;
  }

  .react-date-picker__calendar--open {
    @apply mt-2 -ml-px;

    .react-calendar {
      @apply rounded-md border-gray-200 shadow-md text-black p-2;

      .react-calendar__month-view__weekdays__weekday {
        abbr {
          @apply font-sans !no-underline !font-bold;
        }
      }

      .react-calendar__tile {
        @apply rounded-md;
      }

      .react-calendar__month-view__days__day--weekend:not(.react-calendar__tile--active) {
        @apply text-red-800 font-bold;
      }

      .react-calendar__tile--now {
        @apply bg-yellow-200;
      }

      .react-calendar__tile--active {
        @apply bg-pbpurple-500;
      }
    }
  }
}
